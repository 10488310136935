.admin-form {
    &__error {
        @include error-message;
        margin-bottom: 35px;
    }

    &__review {
        @include success-message;
        margin-bottom: 35px;
    }

    &__geocode {
        &-map {
            width: 100%;

            > div {
                height: 100%;
                min-height: 250px;
            }
        }

        &-button {
            @include link-loading(120, 50);
            width: 100% !important;
        }
    }

    fieldset#ctrl_languages {
        columns: 2;
    }

    .widget-submit {
        margin-top: 35px;
    }
}

@include media(tablet, '.admin-form') {
    &__geocode {
        display: flex;
        gap: 20px;

        &-map {
            width: 70%;
        }

        &-fields {
            width: 30%;
        }

        &-explanation {
            margin-top: 0;
        }

        &-button {
            @include link-loading(200, 50);
        }
    }

    fieldset#ctrl_offering {
        columns: 2;
    }

    .widget-submit {
        margin-top: 70px;
        display: flex;
        justify-content: center;

        button {
            width: auto;
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}

@include media(desktop, '.admin-form') {
    button.geocode {
        @include link-loading(350, 50);
    }
}
