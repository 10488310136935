.ce_hyperlink,
.ce_download {
    margin: 5px 0 35px 0;
    padding: 0;

    a {
        @include link-forward;

        &[target=_blank]:before {
            background-image: url("../../images/link-new--infodrog.svg");
        }
    }

    &.button {
        margin-top: 35px;
        text-align: center;

        a {
            @include link-button();

            &:before {
                content: none !important;
            }
        }
    }

    &.back {
        margin-bottom: 35px;
        @include clearfix();

        a {
            @include link-more(true);
        }
    }

    &--file {
        a:before,
        a[target=_blank]:before {
            background-image: url("../../images/link-file--infodrog.svg");
        }
    }

    &--email {
        a:before,
        a[target=_blank]:before {
            background-image: url("../../images/link-email--infodrog.svg");
        }
    }

    &--phone {
        a:before,
        a[target=_blank]:before {
            background-image: url("../../images/link-phone--infodrog.svg");
        }
    }

    + .ce_download,
    + .ce_hyperlink {
        overflow: hidden;
        margin-top: -30px;
    }
}

.ce_text {
    + .ce_download,
    + .ce_hyperlink {
        margin-top: calc(-35px + 1em);
    }
}

.ce_text,
.ce_hyperlink {
    + .ce_hyperlink {
        > .ce_headline {
            margin-top: 30px;
        }
    }
}

@include media(tablet) {
    .ce_hyperlink,
    .ce_download {
        .section-inherited &,
        .booklet & {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .ce_text,
    .ce_hyperlink {
        + .ce_hyperlink {
            > .ce_headline {
                margin-top: 50px;
            }
        }
    }
}

@include media(desktop) {
    .ce_hyperlink,
    .ce_download {
        a:before {
            top: 4px;
        }
    }
}
