// Rival Sans font from Adobe Fonts
@import url("https://use.typekit.net/nim5iqm.css");

html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;

    @media screen and (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
}

input,
button,
textarea,
select {
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-size: 99%;
    border-radius: 0;
}

textarea {
    resize: none;
}

input::placeholder,
textarea::placeholder {
    color: map-get($font-color, placeholder);
    opacity: 1;
}

textarea,
button,
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="submit"],
input[type="search"] {
    -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

textarea[disabled],
button[disabled],
input[type="email"][disabled],
input[type="tel"][disabled],
input[type="text"][disabled],
input[type="submit"][disabled],
input[type="search"][disabled] {
    cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type="checkbox"][disabled] + label,
input[type="radio"][disabled] + label {
    cursor: not-allowed;
}

table {
    //width: 100%;
    font-size: inherit;
    border-collapse: collapse;
    border-spacing: 0;
}

table td, table th {
    padding: 4px 15px;
    vertical-align: top;
}

tr > :first-child {
    padding-left: 0;
}

th {
    font-weight: map-get($font-weight, medium);
}

caption,
strong,
b {
    font-weight: map-get($font-weight, bold);
}

ul ul {
    margin-bottom: 0;
}

form,
figure,
blockquote {
    margin: 0;
    padding: 0;
}

img,
iframe {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: middle;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
    display: block;
}

html {
    font-size: 16px;
}

body {
    line-height: 1.625;
    font-family: "rival-sans", sans-serif;
    font-size: 16px;
    font-weight: map-get($font-weight, regular);
    color: map-get($font-color, default);
    letter-spacing: 0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: map-get($font-weight, regular);
}

a {
    color: inherit;
    text-decoration: none;
}

caption,
th,
td {
    //noinspection CssOverwrittenProperties
    text-align: left;
    //noinspection CssOverwrittenProperties
    text-align: start;
}

abbr,
acronym {
    font-variant: normal;
    border-bottom: 1px dotted #3c3c3c;
    cursor: help;
}

blockquote,
q {
    quotes: none;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

del {
    text-decoration: line-through;
}

ins {
    text-decoration: none;
}

pre,
code {
    font-family: monospace;
}

/* Vertical align */
.float_left {
    float: left;
}

.float_right {
    float: right;
}

/* Clear floats */
.clear,
#clear {
    height: 0.1px;
    font-size: 0.1px;
    line-height: 0.1px;
    clear: both;
}

/* Hide invisible elements */
.invisible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.list-items,
.mod_navigation,
.mod_changelanguage,
.mod_breadcrumb {
    ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

@include media(desktop) {
    .section-main .mod_article__inside,
    .section-service .mod_article__inside {
        max-width: 1260px;
        margin: 0 auto;
        @include clearfix;
    }
}

.placeholder {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eee 8%, #e5e5e5 18%, #eee 33%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;

    &--lines {
        height: 1.2em;
        margin-bottom: .2em;
    }

    &--headline {
        background: linear-gradient(to right, #ddd 8%, #ccc 18%, #ddd 33%);
        background-size: 1000px 104px;
    }

    &--block {
        width: 25px;
        height: 27px;
    }
}

@keyframes placeholderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes unsticky {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes sticky {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@import "forms";
