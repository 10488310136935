$backgrounds: (
    button: #DB2C3C,
    hover: #B50717,
    active: #2F2F2F,
    service: #FAFAF9,
    inherited: #FAFAF9,
    overlay: #FAFAF9,
    tags: #FAFAF9,
    top-link: #EB4958,
);

$borders: (
    default: #D4D4D4,
    service: #B0AFAF,
    main: #E1081C,
    focus: #DB2C3C,
    active: #2F2F2F,
    widget: #B0AFAF,
    light: #EAEAEA,
);

$font-color: (
    default: #595959,
    headline: #2F2F2F,
    gray: #808080,
    light: #9B9B9B,
    link: #B50717,
    hover: #DB2C3C,
    active: #2F2F2F,
    placeholder: #B0AFAF,
    inverse: #EBEBEB,
);

$font-weight: (
    thin: 100,
    xlight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    xbold: 800,
    black: 900,
);

@mixin media($size, $block: null) {
    $sizes: (
        mobile-only: "max-width: 766px",
        tablet: "min-width: 767px",
        tablet-only: "min-width: 767px) and (max-width: 1219px",
        desktop: "min-width: 1220px",
    );

    @if (map-has_key($sizes, $size)) {
        $size: map-get($sizes, $size);
    } @else if (type_of($size) == 'number') {
        $size: "min-width: #{$size}px";
    }

    @media screen and (#{unquote($size)}) {
        @if ($block) {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

@mixin link($setColor: true) {
    @if ($setColor) {
        color: map-get($font-color, link);
    }

    &:hover {
        color: map-get($font-color, hover);
    }

    &:active {
        color: map-get($font-color, active);
    }
}

@mixin link-forward($top: 2px, $left: 0px, $size: 5) {
    position: relative;
    display: inline-block;
    min-height: $size * 5px;
    padding-left: 45px * 0.2 * $size;
    color: map-get($font-color, link);
    background: none;
    border: none;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        width: $size * 5 - 1px;
        height: $size * 5px;
        left: $left;
        top: $top;
        background: url("../images/link-forward--infodrog.svg") 0 0/#{$size * 25px} #{$size * 5px} no-repeat;
        @content;
    }

    &:hover {
        color: map-get($font-color, hover);

        &:before {
            background-position: ($size * -5px) 0;
        }
    }

    &:active {
        color: map-get($font-color, active);

        &:before {
            background-position: ($size * -10px) 0;
        }
    }
}

@mixin link-more($back: false) {
    @include link-forward(if($back, -3px, 3px), 0px, 5) {
        @if ($back) {
            transform: rotateZ(180deg);
        }
    }

    color: map-get($font-color, default);
    font-size: 13px;
    font-weight: map-get($font-weight, medium);
    line-height: 24px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

@mixin link-button() {
    position: relative;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 45px;
    color: map-get($font-color, link);
    font-size: 13px;
    font-weight: map-get($font-weight, medium);
    line-height: 15px;
    text-decoration: none;
    text-transform: uppercase;
    background: none;
    border: 2px solid map-get($font-color, link);
    border-radius: 1px;
    cursor: pointer;

    &:hover {
        color: #fff;
        background: map-get($font-color, hover);
        border-color: map-get($font-color, hover);
    }

    &:active {
        background: map-get($font-color, active);
        border-color: map-get($borders, active);
    }
}

@mixin link-loading($width, $height, $stroke-width: 15) {
    @include link-button;
    width: $width * 1px;
    height: $height * 1px;
    border: none;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        rect {
            fill: none;
            stroke: map-get($font-color, link);
            stroke-width: 4;
            stroke-dasharray: 422, 0;
            transition: all 0.35s linear;
        }
    }

    &.loading svg rect {
        animation: link-loading 3s infinite cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
        svg rect {
            stroke: map-get($font-color, hover);
        }
    }

    &:active {
        svg rect {
            stroke: map-get($font-color, headline);
        }
    }

    @keyframes link-loading {
        0% {
            stroke-dasharray: $stroke-width;
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dasharray: $stroke-width;
            stroke-dashoffset: $width + $height + $width * .5 - $stroke-width * .5 + 30;
        }
    }
}

@import 'icons';

@mixin clearfix($prop: after) {
    &:#{$prop} {
        display: table;
        clear: both;
        content: "";
    }
}

@mixin shadow {
    //box-shadow: 0 1px 2px 0 map-get($borders, default);
    box-shadow: 0 3px 6px #00000014;
}

@mixin error-message {
    background: map-get($backgrounds, button);
    color: #fff;
    padding: 8px 23px 7px;
    position: relative;
    font-size: .8em;
}

@mixin success-message {
    background: #92db2c40;
    border: 2px solid #92db2c;
    padding: 8px 23px 7px;
    position: relative;
    font-size: .8em;
}
