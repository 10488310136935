.si-location {
    position: relative;
    display: flex;
    padding: 20px 0;
    font-size: 14px;
    line-height: 20px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid map-get($borders, light);

    &--highlight {
        &:before {
            content: "";
            position: absolute;
            top: -1px;
            right: -8px;
            bottom: 0;
            left: -15px;
            border: 1px solid map-get($font-color, light);
            pointer-events: none;
        }
    }

    &__about {
        width: 200px;
        flex-grow: 1;
        padding-right: 15px;

        &--list {
            width: 40%;
            flex-shrink: 0;
            flex-grow: unset;
        }
    }

    &__name {
        font-size: 15px;
        color: map-get($font-color, link);
        font-weight: map-get($font-weight, medium);

        .si-location--highlight &,
        .si-location__link:hover & {
            color: map-get($font-color, hover);
        }

        .si-location__link:active & {
            color: map-get($font-color, active);
        }
    }

    &__unpublished {
        font-size: 12px;
        margin-right: 5px;
        padding: 1px 10px;
        color: #fff;
        text-transform: uppercase;
        background: map-get($backgrounds, active);
        border-radius: 3px;
        cursor: help;
    }

    &__address {
        margin-top: 4px;
        color: map-get($font-color, gray);
    }

    &__contact {
        display: flex;
        margin-top: 15px;

        &-link {
            display: block;
            margin-right: 8px;
            line-height: 27px;
            color: map-get($font-color, gray);

            &--website,
            &--email,
            &--chat {
                @include link(false);
            }
        }

        &--collapsed {
            align-items: center;

            .si-location__contact-link {
                &--website {
                    @include icon(website);
                }

                &--email {
                    @include icon(email);
                }

                &--chat {
                    @include icon(chat);
                }

                &--phone {
                    margin-left: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &--expanded {
            flex-direction: column;

            .si-location__contact-link {
                display: flex;
                align-items: center;
                margin: 8px 0;

                &--website {
                    @include icon(website, 5, 1, before);
                }

                &--email {
                    @include icon(email, 5, 1, before);
                }

                &--phone {
                    @include icon(phone, 5, 1, before, false);
                }
            }
        }
    }

    &__offering {
        display: none;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        padding-left: 5px;
    }

    &__watchlist {
        @include icon(watchlist, 5, 2);

        &--active {
            background-position: iconX(4) iconY(watchlist) !important;
        }
    }

    &__admin {
        @include icon(admin);
    }

    &__details {
        @include icon(details);
    }
}

@include media(tablet, '.si-location') {
    margin-bottom: 8px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid map-get($borders, light);

    &--list {
        padding: 20px 26px 20px 30px;
        border: 1px solid map-get($borders, default);
        border-radius: 5px;
        @include shadow;
    }

    &--highlight {
        border: 1px solid map-get($font-color, light);

        &:before {
            content: none;
        }
    }

    &__about {
        width: 40%;
    }

    &__address {
        margin-top: 7px;
    }

    &__contact {
        margin-top: 25px;
    }

    &__offering {
        display: block;
        flex-grow: 1;
        padding: 0 50px 0 15px;
        width: calc(60% - 30px);

        p {
            margin: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
        }
    }
}

@include media(desktop, '.si-location') {
    margin-left: 0;
    font-size: 16px;
    line-height: 22px;

    &__name {
        font-size: 16px;
    }
}
