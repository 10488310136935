.section-service {
    margin-top: 40px;
    padding: 70px 0 0;
    border-top: 1px solid map-get($borders, light);

    .layout--suchtindex & {
        border-top: none;
    }

    .mod_article__inside {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        padding: 0 30px;

        .ce_image {
            grid-row: 3;
        }

        .ce_text {
            grid-row: 1;
        }

        .ce_hyperlink {
            grid-row: 2;
        }
    }

    .ce_image {
        margin: 0 -30px;
    }

    h2 {
        margin: 0;
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        color: map-get($font-color, headline);
    }

    p {
        margin: 35px 0 0;
        font-size: 23px;
        line-height: 33px;
        color: map-get($font-color, link);

        strong {
            font-weight: map-get($font-weight, medium);
        }
    }

    .ce_hyperlink {
        margin-top: 35px !important;

        a {
            @include link-more();
        }
    }
}

@include media(tablet, '.section-service') {
    padding: 100px 30px 100px;

    .mod_article__inside {
        grid-template-columns: 48% 12% 40%;
        grid-template-rows: auto 35px auto;

        .ce_image {
            grid-column: 1;
            grid-row: 1 / 4;
        }

        .ce_text {
            grid-column: 3;
            grid-row: 1;
            align-self: end;
        }

        .ce_hyperlink {
            grid-column: 3;
            grid-row: 3;
            align-self: start;
        }
    }

    .ce_image {
        margin: 0;
    }

    h2 {
        font-size: 17px;
        line-height: 20px;
    }

    p {
        margin-top: 55px;
        font-size: 34px;
        line-height: 44px;
    }

    .ce_hyperlink {
        margin-top: 0 !important;
    }
}

@include media(desktop, '.section-service') {
    .mod_article__inside {
        max-width: 1260px;
        margin: 0 auto;
    }
}
