.share {
    &__overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: map-get($backgrounds, active);
        opacity: .2;
        z-index: 100;
    }

    &__content {
        position: fixed;
        top: 50vh;
        left: 50vw;
        width: calc(100vw - 50px);
        max-width: 600px;
        padding: 30px 50px;
        transform: translate3d(-50%, -50%, 0);
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px #00000029;
        z-index: 102;
    }

    &__title {
        font-weight: map-get($font-weight, bold);
    }

    &__link {
        font-size: .9em;
        overflow-wrap: break-word;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 20px;
        border: none;
        background: none;
        cursor: pointer;
    }

    &__actions {
        margin-top: 2em;
        text-align: center;
    }

    &__clipboard {
        @include link-button;
    }
}
