$links: 'video', 'share', 'watchlist', 'print';

.section-subheader {
    display: block;
    position: relative;
    overflow-x: hidden;

    &__inside {
        display: flex;
        justify-content: space-between;
    }

    &__breadcrumb {
        margin: 15px 0 15px 25px;
        color: map-get($font-color, light);
        font-size: 8px;
        line-height: 11px;

        li {
            display: inline;
            text-transform: uppercase;

            &:after {
                // \a0 = &nbsp;
                content: "\a0\a0/\a0 ";
            }

            &.last:after {
                content: none;
            }

            strong {
                font-weight: map-get($font-weight, regular);
            }

            a {
                @include link(false);
            }

            &.first a {
                display: inline-block;
                width: 11px;
                height: 11px;
                background: url("../../images/home.svg") 0 0 no-repeat;
                line-height: 1.8;
                text-indent: -999em;

                &:hover {
                    background-position: -11px 0;
                }

                &:active {
                    background-position: -22px 0;
                }
            }
        }
    }

    &__links {
        margin: 9px;

        ul {
            display: flex;
            justify-content: center;
        }
    }

    &__link {
        a {
            display: flex;
            margin-top: -2px;
            padding: 5px;
            @include link(false);
            text-indent: -999em;
            white-space: nowrap;
        }

        @each $icon in $links {
            &--#{$icon} a {
                @include icon($icon, 4, 1, after);
            }
        }

        &--print {
            display: none;
        }

        .layout--suchtindex & {
            a {
                padding: 2px 20px;
                text-indent: 0;

                &:after {
                    content: none;
                }
            }

            &--watchlist {
                position: relative;
            }

            @each $icon in $links {
                &--#{$icon} a {
                    @include icon($icon, 5, 1, before) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &__watchlist-count {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 16px;
        height: 16px;
        margin-left: 3px;
        padding: 0 3px;
        font-size: 12px;
        line-height: 15px;
        text-indent: 0;
        color: #fff;
        font-weight: map-get($font-weight, medium);
        background: map-get($backgrounds, button);
        border-radius: 20px;
        z-index: 1;

        .layout--sticky &,
        .section-subheader__links--sticky & {
            position: absolute;
            right: 0;
        }

        &:empty {
            display: none;
        }

        &--updated {
            animation: .2s ease-in-out watchlist-updated;
        }

        @keyframes watchlist-updated {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.5);
            }
            100% {
                transform: scale(1);
            }
        }
    }
}

@include media(tablet, '.section-subheader') {
    &__breadcrumb {
        margin: 20px 30px 5px;
        font-size: 14px;
        line-height: 27px;

        li {
            &.first a {
                width: 15px;
                height: 15px;
                line-height: 1;
                background-size: 45px 15px;

                &:hover {
                    background-position: -15px 0;
                }

                &:active {
                    background-position: -30px 0;
                }
            }
        }
    }

    &__links {
        margin: 20px 20px 5px;

        .layout--suchtindex & {
            animation: .3s ease-in-out unsticky;
        }

        .layout--sticky &,
        &--sticky {
            position: fixed;
            top: 14px;
            right: 60px;
            z-index: 100;
            animation: .3s ease-in-out sticky;
        }
    }

    &__link {
        .layout--suchtindex & a,
        a {
            padding: 0;
            margin-left: 25px;
            text-indent: 0;

            &:after {
                content: none;
            }
        }

        &--print {
            display: initial;
        }

        @each $icon in $links {
            &--#{$icon} a {
                @include icon($icon, 5, 1, before) {
                    margin-right: 10px;
                }
            }
        }

        .layout--sticky &,
        .section-subheader__links--sticky & {
            a {
                margin-left: 10px;
                text-indent: -999em;

                &:before {
                    content: none;
                }
            }

            @each $icon in $links {
                &--#{$icon} a {
                    @include icon($icon, 5, 1, after) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@include media(desktop, '.section-subheader') {
    max-width: 1450px;
    margin: 0 auto;

    .layout--suchtindex & {
        display: block;
    }

    &__inside {
        max-width: 1260px;
        margin: 0 auto;
    }

    &__breadcrumb {
        margin: 50px 100px 5px 30px;
    }

    &__links {
        margin: 50px 30px 5px 100px;

        .layout--sticky &,
        &--sticky {
            right: calc((100vw - 1220px) / 2 + 60px);
            margin: 20px;
        }
    }

    &__link {
        a {
            margin-left: 30px !important;
        }
    }
}
