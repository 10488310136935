.layout--content .section-main {
    margin-bottom: 50px;

    &__inside {
        padding: 0 !important;
    }

    .mod_article__inside {
        position: relative;
        padding: 12px 25px 25px;
    }

    @include media(tablet) {
        .mod_article__inside {
            padding: 25px 30px 50px;
        }
    }

    @include media(desktop) {
        margin-bottom: 0;
    }
}

@include media(desktop) {
    .layout--content.layout--narrow .section-main {
        .mod_article__inside {
            max-width: 860px;
        }
    }
}

.section-main {
    [class="^ce_"],
    .admin-form,
    .location-details {
        h2 {
            font-size: 21px;
        }

        h3,
        #ctrl_offering > legend {
            font-size: 18px;
            font-weight: map-get($font-weight, medium);
        }
    }

    .ce_headline,
    .ce_text__headline {
        //font-size: inherit;
        //line-height: inherit;
        //font-weight: map-get($font-weight, medium);
        margin: 35px 0 1em;
    }

    .page-headline,
    .ce_text--lead .ce_text__headline {
        font-size: 23px;
        font-weight: map-get($font-weight, regular);
        line-height: 29px;
        letter-spacing: 0.25px;
        color: map-get($font-color, headline);
    }

    .ce_text {
        margin-top: 35px;
        margin-bottom: 35px;

        &--lead {
            .ce_text__headline {
                margin: 0 0 25px;
            }
        }
    }

    .ce_text,
    .ce_list,
    .location-details__text {
        ul {
            margin: .5em 0 1em;
            padding: 0;
            list-style-type: none;
        }

        p + ul {
            margin-top: -.5em;
        }

        ul {
            li {
                position: relative;
                margin: 0;
                padding: 0 0 0 30px;

                &:before {
                    position: absolute;
                    left: 0;
                    top: -2px;
                    color: map-get($font-color, link);
                    content: "–";
                }
            }
        }
    }

    .ce_table,
    p {
        a {
            @include link();
        }
    }

    p.empty {
        font-style: italic;
    }

    .mod_login,
    .mod_lostPasswordNotificationCenter {
        p.error {
            @include error-message;
            margin-bottom: 2em;
        }
    }

    .mod_registrationNotificationCenter {
        p.confirm {
            @include success-message;
        }
    }
}

@include media(tablet, '.section-main') {
    [class="^ce_"],
    .admin-form,
    .location-details {
        h2 {
            font-size: 26px;
        }

        h3,
        #ctrl_offering > legend {
            font-size: 22px;
        }
    }

    .ce_text {
        &--lead {
            font-size: 19px;
            line-height: 29px;
        }
    }

    .ce_headline,
    .ce_text__headline {
        margin-top: 50px;
    }

    h2 + .ce_headline,
    h2 + .ce_text > .ce_text__headline {
        margin-top: 0;
    }

    .page-headline,
    .ce_text--lead .ce_text__headline {
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 0.34px;
        margin: 35px 0 40px;
    }
}
