.location-details {
    h3 {
        margin: 30px 0 10px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    &__name {
        margin: 0;
        font-size: 22px;
        line-height: 1.4;
        font-weight: map-get($font-weight, light);

        strong {
            color: map-get($font-color, hover);
            font-weight: map-get($font-weight, light);
        }
    }

    &__section {
        margin-top: 30px;
        padding-top: 5px;
        border-top: 1px solid #eaeaea;

        &-margin {
            margin-top: 30px !important;
        }
    }

    &__map {
        margin: 30px -25px 0;

        > div {
            height: 280px;
        }
    }

    &__address {
        display: flex;
        min-height: 27px;
        margin-bottom: 7px;
        line-height: 25px;

        @include icon(pin, 5, 2, before, false);
    }

    &__links {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            padding: 0;
        }
    }

    &__link {
        display: flex;
        margin: 4px 0 !important;
        line-height: 25px;
        overflow-wrap: anywhere;

        &--email {
            @include icon(email, 5, 2, before, false);
        }

        &--phone {
            @include icon(phone, 5, 2, before, false);
        }

        &--fax {
            @include icon(fax, 5, 2, before, false);
        }

        &--website {
            @include icon(website, 5, 2, before, false);
        }

        &--chat {
            @include icon(chat, 5, 2, before, false);
        }

        a {
            @include link(false);
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            position: relative;
            margin: 0;
            padding: 3px 0 3px 30px;

            &:before {
                content: "";
                position: absolute;
                top: 7px;
                left: 0;
                display: block;
                width: 17px;
                height: 17px;
                background: url("../../images/check.svg") 0 0 no-repeat;
            }
        }
    }

    &__list-inline {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin: 0;
            padding: 0;

            &:after {
                content: ", ";
            }

            &:last-child:after {
                content: none;
            }
        }
    }
}

@include media(tablet, '.location-details') {
    max-width: 900px;
    margin: 0 auto;

    font-size: 18px;
    line-height: 30px;

    h2 {
        + .location-details__row h3:first-child {
            margin-top: 0;
        }
    }

    &__header {
        margin: 20px 0 70px;

        &--nomap {
            margin-bottom: 40px;
        }
    }

    &__watchlist {
        margin-top: 10px;
    }

    &__name {
        font-size: 32px;
        line-height: 1.3;
    }

    &__section {
        margin-top: 40px;
        padding-top: 20px;

        &-margin {
            margin-top: 40px !important;
        }
    }

    &__map {
        margin: 0;
        padding: 0;

        > div {
            width: 100%;
            height: 325px;
        }
    }

    &__address {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    &__link {
        margin: 7px 0 !important;
    }

    &__row {
        display: flex;
        gap: 50px;
    }

    &__column {
        width: 45%;

        + .location-details__column {
            width: 55%;
        }
    }

    &__list {
        &--two-columns {
            columns: 2;
            gap: 50px;
        }

        li {
            padding: 4px 0 4px 30px;
            line-height: 24px;

            &:before {
                top: 9px;
            }
        }
    }
}
