
.widget {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 0 18px;
    color: map-get($font-color, default);
    font-size: inherit;

    > label {
        position: absolute;
        left: 13px;
        top: -12px;
        z-index: 1;
        margin: 0 1px 0 0;
        padding: 0 10px;
        background: #fff;
        font-size: 14px;
        line-height: 25px;
        height: 25px;
        max-width: calc(100% - 20px);
        transition: all .2s;
        cursor: text;
        overflow: hidden;
        word-break: break-all;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    textarea {
        width: 100%;
    }

    input.text,
    input.captcha,
    select,
    textarea {
        width: 100%;
        padding: 16px 23px 8px;
        font-size: inherit;
        border: 1.5px solid map-get($borders, widget);
        border-radius: 0;

        &.no-label {
            padding-top: 15px;
            padding-bottom: 13px;
        }

        &::placeholder {
            opacity: 1;
            transition: all .2s;
        }

        &:placeholder-shown:not(:focus):not(.no-label)::placeholder {
            opacity: 0;
        }

        &:focus {
            outline: none;
            border-color: map-get($borders, focus);
        }

        &:focus + label {
            color: map-get($font-color, link);
        }

        &:placeholder-shown:not(:focus) + label,
        &.no-placeholder:not(:focus) + label,
        &.empty + label {
            top: 13px;
            color: map-get($font-color, placeholder);
            font-size: inherit;
            pointer-events: none;
        }
    }

    input[type=number] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    select {
        padding-right: 1em;
        background: url("../images/widget-select.svg") no-repeat right 16px center;
        background-size: 16px 9px;
    }

    &-submit {
        border: none;

        button {
            display: block;
            width: 100%;
            height: 56px;
            padding: 16px 15px 14px;
            color: #fff;
            font-size: 13px;
            font-weight: map-get($font-weight, bold);
            line-height: 15px;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            background: map-get($backgrounds, button);
            border: none;
            cursor: pointer;

            &:hover {
                background: map-get($backgrounds, hover);
            }
        }
    }

    &-explanation {
        display: flex;
        align-items: center;
        margin-bottom: 0 !important;

        &.note {
            margin-bottom: 3em !important;
            font-size: 15px;
            line-height: 24px;
        }

        p {
            margin-top: 0;
        }

        a {
            @include link();
        }
    }

    &-counter {
        font-size: 14px;
        font-style: italic;
        padding-left: 15px;
    }

    &-captcha {
        .captcha_text {
            order: 1;
            padding: 10px 21.5px 0;
            position: relative;
            font-size: .8em;
            border: 1.5px solid map-get($borders, widget);
            border-bottom: none;
        }

        input.captcha {
            order: 2;
            padding-top: 8px !important;
            border-top: none !important;

            &:focus + .captcha_text {
                border-color: map-get($borders, focus);
            }
        }
    }

    label,
    legend {
        > .mandatory {
            color: map-get($font-color, link);
            margin-left: 3px;
        }
    }

    p.error {
        order: 10;
        @include error-message;
        margin: 10px 0 0;

        &:before {
            position: absolute;
            top: -5px;
            left: 28px;
            width: 10px;
            height: 10px;
            background: map-get($backgrounds, button);
            transform: rotateZ(45deg);
            content: "";
        }
    }
}

.radio_container ,
.checkbox_container {
    > legend {
        margin: 0 0 .2em;
        padding: 0;
        font-weight: map-get($font-weight, bold);
    }

    > span,
    > fieldset > span {
        display: block;
    }

    // Grouped fields
    fieldset {
        > legend {
            margin: 0 0 .2em;
            padding: 0;
            font-size: 0.9em;
            font-weight: map-get($font-weight, bold);
        }

        + fieldset {
            margin-top: 1em;
        }
    }

    &.inline > span,
    &.inline > fieldset > span {
        display: inline-block;
        margin-right: 1em;
    }

    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;

        + label {
            display: block;
            position: relative;
            padding: 0.1em 0 0.1em 1.5em;
            line-height: 1.2;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: .3em;
                display: block;
                width: 1em;
                height: 1em;
                border: 1px solid map-get($borders, widget);
                border-radius: 3px;
            }

            &:after {
                content: "";
                position: absolute;
                display: block;
            }
        }

        &:focus + label,
        & + label:hover {
            &:before {
                border-color: map-get($borders, active);
            }
        }

        &:disabled + label {
            color: map-get($font-color, light);

            &:before {
                border-color: map-get($borders, widget) !important;
            }
        }
    }
}

.radio_container input[type="radio"] {
    + label {
        &:before {
            border-radius: 50%;
        }
    }

    &:checked + label {
        &:after {
            left: 3px;
            top: calc(50% - .3em + 1px);
            width: calc(1em - 6px);
            height: calc(1em - 6px);
            background: map-get($backgrounds, active);
            border-radius: 50%;
        }
    }
}

.checkbox_container input[type="checkbox"] {
    &:checked + label {
        &:before {
            background: map-get($backgrounds, active);
            border-color: map-get($borders, active);
        }

        &:after {
            left: 0;
            top: .3em;
            width: 1em;
            height: 1em;
            background: url("../images/checkbox.svg") center center no-repeat;
            background-size: contain;
        }
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

.ce_form {
    hr {
        width: 100%;
        margin: 0 0 15px;
        border-top: 1px solid map-get($borders, default);
        border-bottom: 0;
    }
}


@include media(tablet) {
    .widget {
        margin-bottom: 23px;

        &-explanation {
            &.note {
                margin-bottom: 5em;
            }
        }
    }

    fieldset {
        &.group-50-50,
        &.group-30-70,
        &.group-70-30,
        &.group-33-33-33 {
            display: flex;
            margin-left: -15px;
            margin-right: -15px;

            > .widget {
                margin-left: 15px;
                margin-right: 15px;
            }

            > .widget-text {
                margin-left: 5px;
                margin-right: 5px;

                &:first-child {
                    margin-left: 15px;
                }

                &:last-child {
                    margin-right: 15px;
                }
            }
        }

        &.group-50-50 {
            > .widget {
                width: 50%;
            }
        }

        &.group-30-70 {
            > .widget:nth-child(odd) {
                width: 30%;
            }

            > .widget:nth-child(even) {
                width: 70%;
            }
        }

        &.group-70-30 {
            > .widget:nth-child(odd) {
                width: 70%;
            }

            > .widget:nth-child(even) {
                width: 30%;
            }
        }

        &.group-33-33-33 {
            > .widget {
                width: 33.33333333%;
            }
        }
    }

    .ce_form {
        hr {
            margin-bottom: 25px;
        }

        .formbody {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;

            > .widget,
            > fieldset {
                width: calc(50% - 20px);
                margin-left: 10px;
                margin-right: 10px;

                &.full-width {
                    width: 100% !important;
                }

                &.break-after {
                    margin-right: 50% !important;
                }

                &.push-right {
                    margin-left: calc(50% + 5px) !important;
                }
            }

            > fieldset {
                &.group-50-50,
                &.group-30-70,
                &.group-70-30,
                &.group-33-33-33 {
                    width: calc(50% - 10px);
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

            > .widget-submit {
                width: 25%;
                margin-left: 75%;
            }
        }
    }
}
