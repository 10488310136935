.section-footer {
    padding: 27px 0 24px;
    color: map-get($font-color, inverse);
    font-size: 13px;
    line-height: 19px;
    background: map-get($backgrounds, hover);

    &__inside {
        position: relative;
        padding: 0 20px;
    }

    &__navigation {
        li {
            display: inline;
            padding-right: 20px;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

@include media(tablet, '.section-footer') {
    padding: 40px 0 37px;
    font-size: 15px;

    &__inside {
        padding: 0 30px;
    }
}

@include media(desktop, '.section-footer') {
    &__inside {
        max-width: 1260px;
        margin: 0 auto;
    }
}
