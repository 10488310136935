
body {
    &.layout--overlay {
        overflow: hidden;
        position: fixed; // https://bugs.webkit.org/show_bug.cgi?id=153852#c43
        width: 100vw;
    }

    @include media(tablet) {
        padding-top: 95px;

        &.layout--overlay {
            position: static;
        }
    }
}

.section-header {
    height: 50px;
    background: #fff;
    border-top: 4px solid map-get($borders, main);
    transition: border-top-color .3s ease-in-out;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 50px;
        box-shadow: 0 .5px 2px 0 map-get($borders, default);
        pointer-events: none;
        z-index: 50;
    }

    .layout--suchtindex &:after {
        @include media(mobile-only) {
            content: none;
        }
    }

    &__logo {
        display: inline-block;
        margin: 10px 0 10px 25px;

        a {
            display: block;
            width: 190px;
            height: 25px;
            background: url('../../images/infodrog_logo_de.svg') left top no-repeat;
            background-size: contain;
            text-indent: -999em;

            html[lang=fr] & {
                background-image: url('../../images/infodrog_logo_fr.svg');
            }

            html[lang=it] & {
                background-image: url('../../images/infodrog_logo_it.svg');
            }

            html[lang=en] & {
                background-image: url('../../images/infodrog_logo_en.svg');
            }

            .layout--sticky & {
                width: 65px;
                background-image: url('../../images/infodrog_logo.svg') !important;
            }
        }

    }

    &__navtoggle {
        position: absolute;
        right: 10px;
        top: 6px;
        width: 42px;
        height: 42px;
        padding: 20px 10px 0;

        span, span:before, span:after {
            cursor: pointer;
            border-radius: 2px;
            height: 1.5px;
            width: 22px;
            background: map-get($backgrounds, hover);
            position: absolute;
            display: block;
            content: '';
            transition: all .2s ease-in-out;
        }

        span:before {
            top: -6px;
        }

        span:after {
            bottom: -6px;
        }

        .layout--overlay & {
            span {
                background-color: transparent;

                &:before {
                    top: 0;
                    transform: rotate(45deg);
                }

                &:after {
                    top: 0;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    //noinspection CssOverwrittenProperties
    &__overlay {
        position: fixed;
        z-index: 50;
        left: 0;
        right: 0;
        bottom: 0;
        top: 50px;
        height: calc(100vh - 50px);
        height: calc((var(--vh, 1vh) * 100) - 50px);
        padding-bottom: 56px;
        background: #fff;
        transition: opacity .3s ease-in-out;
        pointer-events: none;
        opacity: 0;

        .layout--overlay & {
            opacity: 1;
            pointer-events: initial;
        }
    }

    //noinspection CssOverwrittenProperties
    &__navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        max-width: 600px;
        margin: 0 auto;
        padding: 70px 0;
        font-size: 21px;
        line-height: 1.5;
        text-align: center;

        a {
            @include link();
            display: block;
            padding: 25px;
            font-weight: map-get($font-weight, regular);
            text-transform: uppercase;
        }
    }

    &__languages {
        ul {
            display: flex;
            justify-content: center;
        }

        a {
            @include link();
            margin: 0 10px;
            padding: 10px;
        }
    }
}

@include media(tablet, '.section-header') {
    position: fixed;
    top: 0;
    width: 100%;
    height: 95px;
    z-index: 100;
    border-top: 8px solid map-get($borders, main);

    &:after {
        height: 77px;
        box-shadow: 0 1px 2px 0 map-get($borders, default);
    }

    &__logo {
        margin: 16px 0 0 30px;

        a {
            width: 300px;
            height: 40px;
        }
    }

    &__navtoggle {
        top: 17px;
        right: 20px;
    }

    &__overlay {
        top: 95px;
        height: calc(100vh - 95px);
        height: calc((var(--vh, 1vh) * 100) - 95px);
    }

    &__navigation {
        font-size: 24px;
    }
}

@include media(desktop, '.section-header') {
    &__inside {
        position: relative;
        max-width: 1260px;
        margin: 0 auto;
    }
}
